// ROOT_URL - хранится доменное имя сервера
// export const ROOT_URL = 'https://online-back.kartli.ch';

const curUrl = new URL(window.location.href);

const serverPort = '';
export const ROOT_URL = curUrl.protocol + '//' + curUrl.hostname.replace('online', 'online-back') + serverPort;

// конструктор ошибок от Бэка
function BackEndError(message) {
  this.message=message;
}
BackEndError.prototype=Object.create(Error.prototype);
BackEndError.prototype.name='BackEndError';

// конструктор ошибок от 1C
// eslint-disable-next-line camelcase
function OneSError(ErrorField) {
  // debugger;
  // content_type JSON, то парсим ответ
  if ('content_type' in
      ErrorField && ErrorField.content_type.startsWith('application/json')) {
    const parsing=JSON.parse(ErrorField.external_error_text);
    const report='report' in parsing?parsing.report:undefined;
    // если есть details и он заполнен, то добавляем
    const details=('details' in parsing && parsing.details)?' '+parsing.details:'';
    this.message=report+details;
    // иначе не парсим, а берем как есть
  } else this.message=ErrorField.external_error_text;
}
OneSError.prototype=Object.create(Error.prototype);
OneSError.prototype.name='OneSError';

function ExternalSysError(message) {
  this.message=message;
}
ExternalSysError.prototype=Object.create(Error.prototype);
ExternalSysError.prototype.name='ExternalSysError';

// eslint-disable-next-line camelcase
async function handle_http_status(response) {
  // debugger;
  if (response.ok) {
    // console.log('получен http ответ OK');
    return Promise.resolve(response);
  } else {
    // если http ответ не входит в диапазон 200-299, то выбрасываем ошибку
    // console.log('получен HTTP ответ с ошибкой:', response.status);
    // debugger;

    const contentType= response.headers.get('content-type');
    if (contentType.startsWith('application/json')) {
      const errorData = await response.json();

      if (errorData.no_packed) {
        return Promise.reject(new ExternalSysError(errorData));
      }

      return Promise.reject(new ExternalSysError(errorData.short_error + '\n\n' + errorData.description));
    } else {
      return Promise.reject(new Error(response.statusText));
    }
  }
}


// вторая ступень - проверка типа возвращаемого контента и соответствующий парсинг
// eslint-disable-next-line camelcase
async function handle_content_type(response) {
  // получаем content-type
  // debugger;
  const contentType= response.headers.get('content-type');
  if (contentType == null) {
    return {'result': 'ok'};
  } else if (contentType.startsWith('application/json')) {
    // console.info('HTTP обрабатываем как JSON');
    const curJson = await response.json();
    return {'result': curJson, 'time': response.headers.get('ETAG-DATE')};
  } else if (contentType.startsWith('text')) {
    // console.info('HTTP обрабатываем как TEXT');
    const curText = await response.text();
    return {'result': curText, 'time': undefined};
  } else /* if (contentType.startsWith('application/')) */ {
    // const extention = contentType.split('/')[1];
    // console.info('HTTP обрабатываем как скачиваемый файл');
    const curText = await response.blob();
    const blob = new Blob([curText], {type: contentType});
    const url = window.URL.createObjectURL(blob);
    return {'result': {url}, 'is_file': true};
  // } else {
    // console.log('content-type пришёл неожиданный');
    // throw new BackEndError('неожиданный ответ от сервера');
  }
}

// третья ступень - разбор ответа от сервера, выявляем если это ошибки от Бэка или 1С
// eslint-disable-next-line camelcase
function handle_response_back(response) {
  if (response.is_file || typeof response.result === 'string' || response.result instanceof String) {
    return response;
  }

  const time = response.time;
  response = response.result;
  // debugger;
  if ('error' in response && response.error) {
    // определили как ошибку от Бэка
    throw new BackEndError(response.data);
  } else {
    if (!('data' in response)) {
      if (time) {
        response.__time__ = time;
      }
      return response;
    }
    if ('status' in response.data && response.data.status!==200) {
      // определили как ошибку от 1С
      throw new OneSError(response.data);
    } else
    if (response.data.result.error) {
      // определили как ошибку от некоей внешней системы по признаку
      throw new ExternalSysError(response.data.result.data);
    }
    return response.data;
    // ошибок видимо нет (но это не точно)
  }
}

export function dataRequester({url, method, headers, body, files, ignoreError}) {
  const myHeaders = new Headers();

  if (headers) {
    Object.entries(headers).forEach(([key, value])=>{
      myHeaders.append(key, value);
    });
  }

  // myHeaders.append('Content-Type', 'text/plain;charset=UTF-8');
  // debugger;
  let bodyRequest;
  if (files) {
    bodyRequest = new FormData();
    bodyRequest.append('params', JSON.stringify(body.params));
    Object.entries(files).forEach(([key, value])=>{
      bodyRequest.append(`file_${key}`, value);
    });
  } else {
    bodyRequest = JSON.stringify(body);
  }
  // eslint-disable-next-line camelcase
  const request_options = {
    method,
    credentials: 'include',
    headers: myHeaders,
    body: bodyRequest,
  };

  if (location.protocol == 'https:' && url.startsWith('http:')) {
    url = url.replace('http:', 'https:');
  }

  const ans = fetch(url, request_options)
      .then(handle_http_status)
      .then(handle_content_type)
      .then(handle_response_back);

  if (!ignoreError) {
    ans.catch((errData) => {
      if (errData instanceof TypeError && errData.message === 'Failed to fetch') {
        let userText = 'Невозможно выполнить запрос\nв течении 30 секунд.\n\nПопробуйте задать более простые\nфильтры!';

        if (!window.__is_online__) {
          userText = 'Отсутствует соединение с\nинтернетом!';
        }

        window.globalEvents.callEvent('new_message', [{
          messageType: 'warning',
          messageData: userText,
        }]);
      } else {
        // обработка ошибок
        window.globalEvents.callEvent('new_message', [{
          messageType: 'error',
          messageData: errData,
        }]);
      }
    });
  }

  return ans;
}
