<template>
  <SettingsModal v-if = "this.settingsModal"
                 @closeSettingsModal = "this.toggleSettingsModal"/>
  <div class = "profile-frame" ref = "profile_frame">
    <div class = "profile-frame__preview">
      <IconComponent size = "28"/>
      <LabelComponent v-if = "!this.get_is_mobile_info" class = "profile-frame__username"
                      label_type = 'button/large/medium 14'
                      :label_text = "get_user_full_name"/>

      <IconComponent v-if = "!this.get_is_mobile_info"
                     type = 'svg' name = "caret-circle-down" size = "16"
                     :class = "{'profile-frame__icon':true, 'profile-frame__icon-opened':this.dropped}"/>
    </div>
    <div class = "profile-frame__user-menu" v-if = "this.dropped">
      <span ref = "dropdown">
        <ProfileUserMenu :menuItems = "menuItems" @select_elem = "select_elem"/>
        <ProfileUserMenuMobile :menuItems = "menuItems"
                               @close_modal = "dropped=false" @select_elem = "select_elem"/>
      </span>
    </div>
  </div>
</template>

<script>
import LabelComponent from '../../unit/Label';
import IconComponent from '../../unit/Icon';
import ProfileUserMenu from './ProfileUserMenu';
import ProfileUserMenuMobile from './ProfileUserMenuMobile';
import builder from '../../../assets/v1/js/builder';
import logoutUser from '../../../assets/v1/js/project_management/dataSources/logoutUser';
import SettingsModal from './SettingsModal';


export default builder({
  name: 'ProfileFrame',

  data: () => ({
    dropped: false,
    settingsModal: false,
  }),
  components: {
    IconComponent,
    LabelComponent,
    ProfileUserMenu,
    ProfileUserMenuMobile,
    SettingsModal,
  },
  computed: {
    get_user_info() {
      return this.$store.state.auth_settings.user_obj;
    },
    get_user_full_name() {
      if (!this.get_user_info) return '__anonymous__';
      if (!this.get_user_info['first_name'] && !this.get_user_info['last_name']) return this.loc('Неизвестное имя');
      return [this.get_user_info['first_name'], this.get_user_info['last_name']].join(' ');
    },
    menuItems() {
      if (this.get_user_info.is_anonymous) {
        return [
          {
            name: this.loc('Cайт компании'),
            icon: 'globe',
            callFunc: () => {
              location.href = 'https://kartli.ru';
            },
          },
          {
            name: this.loc('Вход'),
            separatorTop: true,
            icon: 'sign-out',
            callFunc: () => {
              location.href = '/login';
            },
          },
        ];
      }

      return [
        {
          name: this.loc('Настройки'),
          separatorBottom: true,
          icon: 'gear',
          callFunc: () => {
            this.settingsModal = true;
          },
        },
        {
          name: this.loc('Cайт компании'),
          icon: 'globe',
          callFunc: () => {
            location.href = 'https://kartli.ru';
          },
        },
        /* {
          name: this.loc('Торги'),
          icon: 'law',
          callFunc: () => {
            location.href = 'https://bidding.kartli.ch';
          },
        },
        {
          name: this.loc('Справочник продукции'),
          icon: 'handbook',
          callFunc: () => {
            location.href = 'https://reference.kartli.ch';
          },
        }, */
        {
          name: this.loc('Выход'),
          separatorTop: true,
          icon: 'sign-out',
          callFunc: () => {
            logoutUser(() => {
              location.reload();
            }).makeRequest();
          },
        },
      ];
    },
  },

  props: ['user_info'],
  created() {
    this.menuItems.map((menuItem, idx) => {
      menuItem.id = idx;
    });
    document.addEventListener(
        'click',
        this.clickOutHandler,
    );
  },
  methods: {
    toggleSettingsModal() {
      this.settingsModal = false;
    },
    select_elem(id) {
      this.dropped = false;
      this.menuItems[id].callFunc();
    },
    to_exit() {
      document.cookie = 'sessionKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      document.cookie = 'sessionKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=' + window.location.pathname;
      location.reload();
    },
    clickOutHandler(event) {
      let container = this.$refs.profile_frame;
      if (container && !container.contains(event.target)) {
        this.dropped = false;
      } else {
        container = this.$refs.dropdown;
        if (!container || !container.contains(event.target)) {
          this.dropped = !this.dropped;
        }
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener(
        'click',
        this.clickOutHandler,
    );
  },
});

</script>

<style lang = "less">
@import url('../../../assets/v1/less/base.less');

@profile__username__text__color: @dark;
@profile-frame__preview__text__color: @dark;
@profile-frame__preview__icon__color: @light-gray;

.profile-frame {
  .flex(column, flex-start, flex-start);

  &__preview {
    .flex(row, space-between, center);
    position: relative;
    color: @profile-frame__preview__text__color;
    user-select: none;
    .set_icon_color(@profile-frame__preview__icon__color);

    &:hover {
      cursor: pointer
    }
  }

  &__username {
    margin-left: 5px;
    color: @profile__username__text__color;
  }

  &__user-menu {
    width: 100%;
    overflow: visible;
    position: relative;
  }

  &__icon {
    margin-left: 10px;
    transition: transform 0.2s;

    &-opened {
      transform: rotate(-180deg);
    }
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .profile-frame {
    &__username {
      display: none
    }

    &__icon {
      display: none
    }
  }
}
</style>
